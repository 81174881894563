import { rekeyClaimAction, resetRekey } from 'feature/claims/claimSlice';
import { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';

import { ReactComponent as CloseDialog } from 'assett/icons/close_dialog_icon.svg';
import lodash from 'lodash';
import { useLocation } from 'react-router-dom';
import { IconButton } from 'styles';
import Button from 'styles/button/Button';
import Dialog from 'styles/dialog/SimpleDialog';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import PriceEstimate from './PriceEstimate';

const DialogRekey: FC<{
	claim: IClaim;
	open: boolean;
	setOpen: (value: boolean) => void;
}> = ({ claim, open, setOpen }) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();

	const { rekeyClaim } = useSelector((state: RootState) => state.claim);
	const [formData, setFormData] = useState<IClaim>(lodash.cloneDeep(claim));
	const [status, setStatus] = useState<
		'beforeCalculation' | 'afterCalculation' | 'onEdit'
	>('beforeCalculation');
	const splittedLocation = location.pathname.split('/');

	useEffect(() => {
		setFormData(lodash.cloneDeep(rekeyClaim || claim)); //initially the original claim is loaded. then when calculation is done the rekey value is saved and calculated values should be shown
	}, [claim, rekeyClaim]);

	useEffect(() => {
		if (status === 'afterCalculation' && !rekeyClaim) {
			setOpen(false); // it means that we did the submission and the window needs to be closed
		}
	}, [rekeyClaim, setOpen, status]);

	useEffect(() => {
		if (open) {
			setStatus('beforeCalculation');
		}
	}, [dispatch, open]);

	return (
		<Dialog
			open={open}
			close={
				<IconButton onClick={() => setOpen(false)}>
					<CloseDialog />
				</IconButton>
			}
			title={tClientLabels('rekey.title')}
			events={true}
			actions={
				<Fragment>
					<Button
						variant="outlined"
						onClick={() => {
							setOpen(false);
							dispatch(resetRekey());
						}}
					>
						{tClientLabels('commons.buttons.cancel')}
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							dispatch(
								rekeyClaimAction({
									id: splittedLocation[splittedLocation.length - 1],
									formData: rekeyClaim as IClaim,
								}),
							);
						}}
						disabled={status !== 'afterCalculation'}
					>
						{tClientLabels('commons.buttons.confirm')}
					</Button>
				</Fragment>
			}
			fullWidth={false}
			maxWidth={false}
		>
			<PriceEstimate
				formData={formData}
				setFormData={setFormData}
				status={status}
				setStatus={setStatus}
				showGross={false}
			/>
		</Dialog>
	);
};

export default DialogRekey;
