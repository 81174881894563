import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Dayjs } from 'dayjs';
import { IFilterCheckEligibility, sortingDirections } from 'types/Form';

type Payload<T> = {
	[K in keyof T]: {
		name: K;
		value: T[K];
	};
}[keyof T];

const initialState: IFilterCheckEligibility = {
	errorCode: 0,
	errorMessage: '',
	searchCheckElegibility: '',
	buttonSearchCheckElegibility: true,
	size: 10,
	search: false,
	inputFiscalCode: '',
	inputMemberId: '',
	inputFirstName: '',
	inputLastName: '',
	inputDateOfBirth: null,
	dateOfService: null,
	filterDateOfService: null,
	inputClaimId: '',
	inputExternalClaimId: '',
	inputClaimFirstName: '',
	inputClaimLastName: '',
	inputClaimDate: '',
	inputClaimStatus: '',
	inputReferenceNumber: null,
	inputTotalAmount: null,
	sortingFields: '',
	sortingDirections: sortingDirections.DESC,
};

const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		handleSearchValuesForm: (state: IFilterCheckEligibility) => {
			state = { ...state };
		},
		handleSearchValueChange: (
			state: any,
			action: PayloadAction<React.ChangeEvent<HTMLInputElement>>,
		) => {
			const { name, value } = action.payload.target;
			state[name] = value;
		},
		handleChange: (
			state: any,
			action: PayloadAction<{ name: string; value: string | Dayjs | null }>,
		) => {
			state[action.payload.name] = action.payload.value;
		},
		setSearch: (
			state: IFilterCheckEligibility,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.search = payload;
		},
		handleChangeClaimStatus: (
			state: IFilterCheckEligibility,
			action: PayloadAction<string>,
		) => {
			if (action.payload === '') {
				state['inputClaimStatus'] = '';
			} else {
				state['inputClaimStatus'] = action.payload;
			}
		},
		setSortingValues: (
			state: IFilterCheckEligibility,
			action: PayloadAction<{ name: string; value: string }>,
		) => {
			const { name, value } = action.payload;
			state.sortingFields = name;
			state.sortingDirections = value;
		},
		handleDateValueChange: (
			state: IFilterCheckEligibility,
			action: PayloadAction<
				Payload<
					Pick<
						IFilterCheckEligibility,
						'inputDateOfBirth' | 'dateOfService' | 'filterDateOfService'
					>
				>
			>,
		) => {
			const { name, value } = action.payload;

			if (name === 'dateOfService') state.dateOfService = value;
			if (name === 'inputDateOfBirth') state.inputDateOfBirth = value;
			if (name === 'filterDateOfService') state.filterDateOfService = value;
		},
		setDateOfService: (
			state: IFilterCheckEligibility,
			action: PayloadAction<Dayjs | null>,
		) => {
			state.dateOfService = action.payload;
		},
		resetDatesForm: (state: IFilterCheckEligibility) => {
			state.inputDateOfBirth = null;
		},
		checkSearch: (state: IFilterCheckEligibility) => {
			if (
				state.searchCheckElegibility === 'inputByFiscalCode' &&
				!!state.inputFiscalCode
			) {
				state.buttonSearchCheckElegibility = false;
			}
		},
		resetForm: () => initialState,
	},
	extraReducers: builder => {},
});

export const {
	handleSearchValueChange,
	resetForm,
	handleDateValueChange,
	setDateOfService,
	checkSearch,
	handleChange,
	handleChangeClaimStatus,
	resetDatesForm,
	setSearch,
	setSortingValues,
} = formSlice.actions;
export default formSlice.reducer;
