import {
	Button as ButtonMui,
	ButtonProps as ButtonPropsMui,
} from '@mui/material';
import React from 'react';

import styled from 'styled-components';

export type ButtonProps = ButtonPropsMui & { component?: string };

const StyledButton = styled(ButtonMui)``;

const Button: React.FC<ButtonProps> = React.forwardRef((props, ref) => {
	return (
		<StyledButton
			{...props}
			ref={ref}
			disableElevation
			disableFocusRipple
			disableTouchRipple
			disableRipple
		>
			{props.children}
		</StyledButton>
	);
});

export default Button;
