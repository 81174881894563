import {
	changePage,
	downloadClaims,
	getClaims,
	setClaimIds,
} from 'feature/claims/claimsSlice';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateClaimStatus } from 'feature/claims/claimSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { AppDispatch, RootState } from 'store/store';
import Button from 'styles/button/Button';
import Pagination from 'styles/pagination/Pagination';
import Stack from 'styles/stack/Stack';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimListFooter = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { isDesktop } = useDevice();
	const { claimIds, pages, page, claims, downloadAll, claimExcludeIds } =
		useSelector((store: RootState) => store.claims);
	const { inputClaimStatus } = useSelector((store: RootState) => store.form);

	const refreshClaimIdsList = useMemo(
		() =>
			!claims?.some(claim => claimExcludeIds.includes(claim.claimId)) &&
			downloadAll,
		[claimExcludeIds, claims, downloadAll],
	);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		event.preventDefault();
		dispatch(changePage(value));
		dispatch(getClaims(''));
	};

	const handleClick = (type: string) => {
		dispatch(downloadClaims({ id: 0, type: type }));
	};

	useEffect(() => {
		if (refreshClaimIdsList && claims)
			dispatch(setClaimIds(claims.map(e => e.claimId)));
	}, [claims, dispatch, refreshClaimIdsList]);

	const handleStatusClick = (approve: boolean) => {
		dispatch(
			updateClaimStatus({ claimIds: [...claimIds], statusId: approve ? 4 : 8 }),
		);
	};

	const isDisabledActionButton = () => {
		if (claimIds.length > 0) {
			const statusSelected = claims
				?.filter(c => claimIds.includes(c.claimId))
				.map(c => c.claimStatus);

			if (downloadAll && inputClaimStatus !== 'Submitted') {
				return true;
			}
			if (statusSelected?.some(c => c !== 'Submitted')) {
				return true;
			}
			return false;
		}
		return true;
	};

	return (
		<Stack
			direction={'row'}
			position={'relative'}
			sx={{ my: !!isDesktop ? 4.25 : 3 }}
			alignItems={'center'}
			justifyContent={'space-between'}
		>
			<Stack
				direction={'row'}
				flexGrow={1}
				sx={{
					justifyContent: !!isDesktop ? 'center' : 'flex-start',
					alignItems: 'center',
				}}
			>
				{pages > 1 ? (
					<Pagination page={page} count={pages} onChange={handleChangePage} />
				) : null}
			</Stack>
			<Stack
				direction={'row'}
				position={'absolute'}
				right={0}
				sx={{
					gap: 2,
					alignContent: 'center',
					justifyContent: 'flex-end',
				}}
			>
				<Button
					type="button"
					variant="contained"
					fullWidth={false}
					onClick={() => handleStatusClick(true)}
					sx={{
						backgroundColor: palette.success.main,
					}}
					disabled={isDisabledActionButton()}
				>
					{tClientLabels('commons.buttons.approve')}
				</Button>
				<Button
					sx={{
						backgroundColor: palette.error.main,
					}}
					disabled={isDisabledActionButton()}
					type="button"
					variant="contained"
					fullWidth={false}
					onClick={() => handleStatusClick(false)}
				>
					{tClientLabels('commons.buttons.reject')}
				</Button>
				<Button
					type="button"
					variant="contained"
					disabled={claimIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('XLSX')}
				>
					{tClientLabels('view-claims.exportButton.XLSX')}
				</Button>
				<Button
					type="button"
					variant="contained"
					disabled={claimIds.length > 0 ? false : true}
					fullWidth={false}
					onClick={() => handleClick('PDF')}
				>
					{tClientLabels('view-claims.exportButton.PDF')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default ClaimListFooter;
