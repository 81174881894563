import { getClaimDetails, resetClaim } from 'feature/claims/claimSlice';
import { useDispatch, useSelector } from 'react-redux';

import ClaimDetails from 'components/ViewClaimDetails/ClaimDetails.component';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';

const ViewClaimDetails = () => {
	const param = useParams<{ id: string }>();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(resetClaim());
		dispatch(getClaimDetails(param.id as string));
	}, [dispatch, param.id]);

	const { claimData: claim } = useSelector((store: RootState) => store.claim);

	return <ClaimDetails claimItem={claim} />;
};

export default ViewClaimDetails;
