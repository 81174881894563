import { FC, useState } from 'react';

import { DesktopDatePicker as MuiDesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ReactComponent as ArrowDownIcon } from 'assett/icons/arrow_down_icon.svg';
import { ReactComponent as ArrowlefttIcon } from 'assett/icons/arrow_left_datepicker_icon.svg';
import { ReactComponent as ArrowRightIcon } from 'assett/icons/arrow_right_datepicker_icon.svg';
import { ReactComponent as ArrowUpIcon } from 'assett/icons/arrow_up_icon.svg';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import IconButton from 'styles/button/IconButton';
import TextField from 'styles/textfield/TextField';
import { useTheme, useTranslate } from 'stylesHooks';
import { OptionsDatepicker } from 'types/Form';
import { TRANSLATION_KEYS } from 'utils/constants';
import { getLanguageStorage } from 'utils/localStorage';
import { dayjsForMaterial } from 'utils/utils';

type Props = {
	value: dayjs.Dayjs | null;
	onChange: (value: dayjs.Dayjs | null) => void;
	label: string;
	variant: keyof typeof OptionsDatepicker;
	minDate?: dayjs.Dayjs | undefined;
	maxDate?: dayjs.Dayjs | undefined;
};

const DatePicker: FC<Props> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const {
		value,
		onChange,
		label,
		variant,
		maxDate: maxDateNF,
		minDate: mindateNF,
	} = props;

	const minDate = dayjsForMaterial(mindateNF);
	const maxDate = dayjsForMaterial(maxDateNF);

	const [open, setOpen] = useState<boolean>(false);
	const theme = useTheme();

	const [invalidDate, setInvalidDate] = useState(false);

	theme.datepicker = OptionsDatepicker[props.variant as OptionsDatepicker];

	return (
		<DesktopDatePicker
			{...props}
			key={`datepicker_${variant}`}
			views={['day']}
			maxDate={maxDate}
			minDate={minDate}
			open={open}
			onOpen={() => setOpen(!open)}
			components={{
				OpenPickerIcon: () => (!open ? <ArrowDownIcon /> : <ArrowUpIcon />),
				LeftArrowIcon: () => <ArrowlefttIcon />,
				RightArrowButton: params => (
					<IconButton {...params}>
						<ArrowRightIcon />
					</IconButton>
				),
			}}
			label={label}
			value={value}
			onChange={e => {
				const newDate = dayjsForMaterial(e as Dayjs);
				const isValidDate =
					!newDate ||
					(typeof newDate?.isValid() !== 'undefined' &&
						newDate.isValid() &&
						(!maxDate || maxDate.diff(newDate) >= 0) &&
						(!minDate || newDate.diff(minDate) >= 0));
				onChange(isValidDate && newDate ? newDate : null);
				setOpen(false);
				if (isValidDate || value) {
					setInvalidDate(false);
				} else setInvalidDate(true);
			}}
			showDaysOutsideCurrentMonth
			renderInput={params => (
				<TextField
					fullWidth
					variant="filled"
					InputLabelProps={{
						style: {
							transform: 'translate(12px 18px)',
						},
					}}
					{...params}
					error={invalidDate}
					inputProps={{
						...params.inputProps,
						placeholder:
							getLanguageStorage() !== 'en-us'
								? tClientLabels('commons.ddmmyyyy')
								: tClientLabels('commons.mmddyyyy'),
					}}
				/>
			)}
			PopperProps={{
				style: {
					background: 'transparent',
					transform: 'translate(50px, 10px)',
				},
			}}
			PaperProps={{
				style: {
					transform: 'translate(20px, 5px)',
				},
			}}
		/>
	);
};

const DesktopDatePicker = styled(MuiDesktopDatePicker)`
	${({ open, theme }) =>
		open ? `border: 1px solid ${theme.palette.primary.main}` : ``};
`;

export default DatePicker;
