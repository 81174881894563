import { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import {
	InputLabel,
	ListItemText,
	MenuItem,
	Radio,
	Select,
	Typography,
} from 'styles';

import FormControl from 'styles/form/FormControl';
import palette from 'styles/theme/Palette';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

interface SelectGenericProps {
	sx?: SxProps<Theme>;
	name: 'claim' | 'contactUs' | 'doctorType' | 'reason';
	items: string[] | { value: string; text: string }[];
	action: (payload: string) => void;
	initialValue?: string;
	error?: boolean;
	errorMessage?: string;
}

const SelectGeneric: FC<SelectGenericProps> = ({
	action,
	items,
	name,
	sx,
	initialValue,
	error,
	errorMessage,
}) => {
	const [selectedValue, setSelectedValue] = useState('');
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const handlerSelectItem = (event: SelectChangeEvent<any | unknown>) => {
		setSelectedValue(event.target.value);
		action(event.target.value);
	};

	useEffect(() => {
		if (initialValue) {
			setSelectedValue(initialValue === 'reset' ? '' : initialValue);
		}
	}, [initialValue]);

	return (
		<FormControl>
			<InputLabel sx={{ color: !!error ? palette.error.main : 'inherit' }}>
				{tClientLabels(`commons.selectGenericHeader.${name}`)}
			</InputLabel>
			<Select
				sx={{ ...sx, height: '56px' }}
				multiple={false}
				variant="filled"
				name={tClientLabels(`commons.selectGenericHeader.${name}`) as string}
				value={selectedValue ?? ''}
				native={false}
				displayEmpty={true}
				renderValue={(e: any) => {
					if (typeof items === 'object') {
						const selectedItem = (items as { value: string; text: string }[]).find(
							el => el.value === e,
						);
						if (!selectedItem) return '';
						return selectedItem.text;
					} else {
						return e;
					}
				}}
				MenuProps={{ PaperProps: { sx: { maxHeight: '268px' } } }}
				onChange={handlerSelectItem}
				disableUnderline
				error={error}
			>
				{items.map(item => {
					return typeof item === 'object' ? (
						<MenuItem key={item.value} value={item.value} disableTouchRipple>
							<Radio checked={selectedValue === item.value} />
							<ListItemText primary={item.text} />
						</MenuItem>
					) : (
						<MenuItem key={item} value={item} disableTouchRipple>
							<Radio checked={selectedValue === item} />
							<ListItemText primary={item} />
						</MenuItem>
					);
				})}
			</Select>
			{error && (
				<Typography
					variant="bodyLink"
					sx={{ position: 'absolute', bottom: 0 }}
					color={palette.error.main}
				>
					{errorMessage || 'This field is mandatory'}
				</Typography>
			)}
		</FormControl>
	);
};

export default SelectGeneric;
