import { getReasonCode, updateClaimStatus } from 'feature/claims/claimSlice';
import { downloadClaims } from 'feature/claims/claimsSlice';
import { setDialogOpen, setInjectionState } from 'feature/hooks/dialogSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import { Box, BoxOverflow } from 'styles';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import palette from 'styles/theme/Palette';
import Tooltip from 'styles/tooltip/Tooltip';
import { useTranslate } from 'stylesHooks';
import { IClaim } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import { ClaimAttachmentsDetails } from './ClaimAttachmentsDetails.component';
import ClaimDetailsReasonCode from './ClaimDetailsReasonCode.component';
import ClaimMemberDetails from './ClaimMemberDetails.component';
import ClaimPaymentDetails from './ClaimPaymentDetails.component';
import ClaimVoidModalContent from './ClaimVoidModalContent.component';
import DialogRekey from './DialogRekey';

const ClaimDetails = ({ claimItem }: { claimItem: IClaim }) => {
	const dispatch = useDispatch<AppDispatch>();
	const param = useParams<{ id: string }>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { claimData: claim } = useSelector((store: RootState) => store.claim);

	useEffect(() => {
		if (claim.claimStatusId === 5) {
			dispatch(getReasonCode(''));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [claim]);

	const handleClick = (type: string) => {
		const claimId = parseInt(param.id as string);
		dispatch(downloadClaims({ id: claimId as number, type: type }));
	};

	const hadleStatusClick = (approve: boolean) => {
		const claimId = parseInt(param.id as string);
		dispatch(
			updateClaimStatus({ claimIds: [claimId], statusId: approve ? 4 : 8 }),
		);
	};

	const [openRekey, setOpenRekey] = useState<boolean>(false);

	return (
		<>
			<DialogRekey claim={claimItem} open={openRekey} setOpen={setOpenRekey} />
			<BoxOverflow
				variant="y"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Stack
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: { lg: 7.5, sm: 3.25 },
						width: '100%',
					}}
				>
					<BoxOverflow
						variant="x"
						sx={{
							width: '100%',
						}}
					>
						<ClaimMemberDetails claim={claimItem} />
					</BoxOverflow>

					{claimItem.reasonCode ? (
						<BoxOverflow
							variant="x"
							sx={{
								width: '100%',
							}}
						>
							<ClaimDetailsReasonCode claim={claimItem} />
						</BoxOverflow>
					) : null}

					<BoxOverflow
						variant="x"
						sx={{
							width: '100%',
						}}
					>
						<ClaimPaymentDetails />
					</BoxOverflow>
					<ClaimAttachmentsDetails />
				</Stack>
				<Stack
					direction={'row'}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						mt: { lg: 7.5, sm: 3.25 },
						alignItems: 'center',
					}}
				>
					<Stack sx={{ alignItems: 'flex-start', paddingBottom: 2, paddingleft: 0 }}>
						<Button
							type="button"
							variant="contained"
							fullWidth={false}
							onClick={() => handleClick('PDF')}
						>
							{tClientLabels('claim.button.download')}
						</Button>
					</Stack>

					<Stack
						direction={'row'}
						sx={{
							gap: 2,
							alignContent: 'center',
							justifyContent: 'flex-end',
							paddingBottom: 2,
						}}
					>
						{
							claim.claimStatusId === 13 ? (
								<Button
									type="button"
									variant="contained"
									fullWidth={false}
									onClick={() => setOpenRekey(true)}
								>
									{tClientLabels('claim.button.rekey')}
								</Button>
							) : null //TODO: add the check if it was voided and there isnt already a rekey
						}

						{claim.claimStatusId === 3 && (
							<>
								<Button
									type="button"
									variant="contained"
									fullWidth={false}
									onClick={() => hadleStatusClick(true)}
									sx={{
										backgroundColor: palette.success.main,
									}}
								>
									{tClientLabels('commons.buttons.approve')}
								</Button>
								<Button
									sx={{
										backgroundColor: palette.error.main,
									}}
									type="button"
									variant="contained"
									fullWidth={false}
									onClick={() => hadleStatusClick(false)}
								>
									{tClientLabels('commons.buttons.reject')}
								</Button>
							</>
						)}
						{claim.claimStatusId === 5 && (
							<Tooltip
								title={tClientLabels('claim.tooltip.voidDisabled')}
								placement="top"
								disableHoverListener={claim.claimInNetwork}
							>
								<Box>
									<Button
										type="button"
										variant="contained"
										fullWidth={false}
										disabled={!claim.claimInNetwork}
										onClick={() => {
											dispatch(
												setInjectionState({
													title: 'Reason',
													content: <ClaimVoidModalContent />,
													buttonClose: true,
												}),
											);
											dispatch(setDialogOpen(true));
										}}
										sx={{
											backgroundColor: palette.black.main,
										}}
									>
										{tClientLabels('claim.button.void')}
									</Button>
								</Box>
							</Tooltip>
						)}
					</Stack>
				</Stack>
			</BoxOverflow>
		</>
	);
};

export default ClaimDetails;
