import { ThunkApiType } from 'store/store';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const searchLocationThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	const { searchLocationValue } = thunkAPI.getState().search;
	const values = {
		name: searchLocationValue,
		size: 10,
	};

	try {
		const response = await authFetch.post('/provider/search', values);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
