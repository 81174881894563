import { ReactComponent as DashboardCheckSubmit } from '../assett/icons/check_eligibility_menu.svg';
import { ReactComponent as DashboardCheckSubmitSelected } from '../assett/icons/check_eligibility_menu_selected.svg';
import { ReactComponent as DashboardContactUs } from 'assett/icons/contact_us_menu.svg';
import { ReactComponent as DashboardContactUsSelected } from 'assett/icons/contact_us_menu_selected.svg';
import { ReactComponent as DashboardGoToMyessilor } from '../assett/icons/go_to_essilor_icon.svg';
import { ReactComponent as DashboardGoToTraining } from 'assett/icons/go_to_training_icon.svg';
import { ReactComponent as DashboardLocationInformation } from '../assett/icons/location_information_menu_icon.svg';
import { ReactComponent as DashboardLocationInformationSelected } from '../assett/icons/location_information_menu_selected.svg';
import { ReactComponent as DashboardProviderManual } from '../assett/icons/provider_manual_menu_icon.svg';
import { ReactComponent as DashboardProviderManualSelected } from '../assett/icons/provider_manual_menu_selected.svg';
import { ReactComponent as DashboardViewClaims } from '../assett/icons/view_claims_menu_icon.svg';
import { ReactComponent as DashboardViewClaimsSelected } from '../assett/icons/view_claims_menu_selected.svg';
import { ReactComponent as DashboardViewPayment } from '../assett/icons/view_payment_menu_icon.svg';
import { ReactComponent as DashboardViewPaymentSelected } from '../assett/icons/view_payment_menu_selected.svg';
import { ReactComponent as HomeIcon } from '../assett/icons/home_icon.svg';
import { ReactComponent as SharedLogout } from '../assett/icons/logout_menu_icon.svg';

export const Home = (t: (t: string) => string) => [
	{
		id: 'dashboard_button_home',
		name: t('dashboard.buttons.home'),
		link: '/home/view-claims',
		icon: <HomeIcon />,
		show: true,
	},
];

export const Dashboard_Actions = (t: (t: string) => string) => [
	{
		id: 'dashboard_button_check_elegibility',
		name: t('dashboard.buttons.checkEligibility'), // TODO: move in translations
		link: '/check-and-submit',
		icon: <DashboardCheckSubmitSelected />,
		unselected_route: <DashboardCheckSubmit />,
		show: true,
	},
	{
		id: 'dashboard_button_view_claims',
		name: t('dashboard.buttons.viewClaims'),
		link: '/home/view-claims',
		icon: <DashboardViewClaimsSelected />,
		unselected_route: <DashboardViewClaims />,
		show: true,
	},
	{
		id: 'dashboard_button_provider_manual',
		name: t('dashboard.buttons.providerManual'),
		link: '/provider-manual',
		icon: <DashboardProviderManualSelected />,
		unselected_route: <DashboardProviderManual />,
		show: true,
	},
	{
		id: 'dashboard_button_contact_us',
		name: t('dashboard.buttons.contactUs'),
		link: '/contact-us',
		icon: <DashboardContactUsSelected />,
		unselected_route: <DashboardContactUs />,
		show: true,
	},
];

export const Dashboard_ManageAccount = (t: (t: string) => string) => [
	{
		id: 'dashboard_button_location_information',
		name: t('dashboard.buttons.locationInformation'),
		link: '/location-information',
		icon: <DashboardLocationInformationSelected />,
		unselected_route: <DashboardLocationInformation />,
		show: true,
	},
	{
		id: 'dashboard_button_view_payments',
		name: t('dashboard.buttons.viewPayments'),
		link: '/view-payment-history',
		icon: <DashboardViewPaymentSelected />,
		unselected_route: <DashboardViewPayment />,
		show: true,
	},
];

export const Dashboard_Go = (t: (t: string) => string) => [
	{
		id: 'dashboard_button_go_to_essilor',
		name: t('dashboard.buttons.goToEssilor'),
		link: 'https://google.com',
		icon: <DashboardGoToMyessilor />,
		show: true,
	},
	{
		id: 'dashboard_button_go_to_training',
		name: t('dashboard.buttons.goToTraining'),
		link: 'https://google.com',
		icon: <DashboardGoToTraining />,
		show: true,
	},
];

export const Logout = (t: (t: string) => string) => [
	{
		id: 'dashboard_buttons_logout',
		name: t('dashboard.buttons.logout'),
		link: '/login',
		icon: <SharedLogout />,
		show: true,
	},
];

export const OutboundLinks = {
	becomingAProvider: {
		href: 'https://google.com',
		target: '_blank',
	},
	cookiesBanner: {
		href: 'https://google.com',
		target: '_blank',
	},
};
