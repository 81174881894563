import { ReactComponent as Download } from 'assett/icons/download.svg';
import { useFileOpener } from 'hooks/downloader.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Button, Stack } from 'styles';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import { camelcaseToUnderscore } from 'utils/utils';

export const ClaimAttachmentsDetails = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const fileOpener = useFileOpener();
	const { claimData: claim } = useSelector((store: RootState) => store.claim);

	const { receipt, rxAttestation, prescription } = claim;

	const attachments = [
		{ documentType: 'prescription', value: prescription },
		{ documentType: 'rxAttestation', value: rxAttestation },
		{ documentType: 'receipt', value: receipt },
	].filter(el => el.value);

	return (
		<Stack flex={'true'} direction={'row'} gap={2.5}>
			{attachments.map(att => (
				<Button
					startIcon={<Download />}
					key={att.documentType}
					variant="outlined"
					onClick={() => {
						const url = `${
							process.env.REACT_APP_API_URL
						}attachments/${camelcaseToUnderscore(att.documentType)}/${
							att.value?.id
						}/content`;
						fileOpener(url, att.value?.fileName);
					}}
				>
					{tClientLabels(`newClaim.upload.docNames.${att.documentType}`)}
				</Button>
			))}
		</Stack>
	);
};
