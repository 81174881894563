import * as React from 'react';

import { styled, SxProps, Theme } from '@mui/system';

interface BoxProps {
	variant: 'x' | 'y' | 'xy';
	children: React.ReactNode;
	sx?: SxProps<Theme>;
}

const StyledBox = styled('div', {
	shouldForwardProp: prop => prop !== 'sx',
	name: 'MuiBoxOverflow',
	slot: 'Root',
	overridesResolver: (props, styles) => [styles.root],
})<BoxProps>({});

const BoxOverflow: React.FC<BoxProps> = props => {
	return <StyledBox {...props}>{props.children}</StyledBox>;
};

export default BoxOverflow;
